import * as fasChevronDown from '@fortawesome/free-solid-svg-icons/faChevronDown'
import * as fasChevronLeft from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import * as fasChevronRight from '@fortawesome/free-solid-svg-icons/faChevronRight'
import * as fasChevronUp from '@fortawesome/free-solid-svg-icons/faChevronUp'
import * as fasFolderOpen from '@fortawesome/free-solid-svg-icons/faFolderOpen'
import * as fasRobot from '@fortawesome/free-solid-svg-icons/faRobot'
import * as fasSnowflake from '@fortawesome/free-solid-svg-icons/faSnowflake'
import * as fasSquare from '@fortawesome/free-solid-svg-icons/faSquare'
import * as fasSync from '@fortawesome/free-solid-svg-icons/faSync'
import * as fasTag from '@fortawesome/free-solid-svg-icons/faTag'
import * as fasTags from '@fortawesome/free-solid-svg-icons/faTags'
import * as fasTrashAlt from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import * as fasUserCircle from '@fortawesome/free-solid-svg-icons/faUserCircle'

export default [
  fasChevronDown.definition,
  fasChevronLeft.definition,
  fasChevronRight.definition,
  fasChevronUp.definition,
  fasFolderOpen.definition,
  fasRobot.definition,
  fasSnowflake.definition,
  fasSquare.definition,
  fasSync.definition,
  fasTag.definition,
  fasTags.definition,
  fasTrashAlt.definition,
  fasUserCircle.definition
] as const
