import { ActionLog, ActionLogFilter, ActionLogsService, CancelablePromise, CancelError } from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'

export interface IHistoryModule {
  actionLogsListStatus: Status<{ promises: CancelablePromise<any>[], landscapeId: string, filter?: ActionLogFilter }, { landscapeId: string, filter?: ActionLogFilter }>
  actionLogChildrenListStatus: Status<{ promise: CancelablePromise<any> }>

  actionLogs: ActionLog[]
  firstActionLog: ActionLog | null
  lastActionLog: ActionLog | null

  actionLogChildren: Record<string, ActionLog[]>
}

const name = 'history'

@Module({
  name,
  namespaced: true
})
export class HistoryModule extends VuexModule implements IHistoryModule {
  static namespace = name

  actionLogsListStatus = new Status<{ promises: CancelablePromise<any>[], landscapeId: string, filter?: ActionLogFilter }, { landscapeId: string, filter?: ActionLogFilter }>()
  actionLogChildrenListStatus = new Status<{ promise: CancelablePromise<any>, landscapeId: string, actionLogId: string }, { landscapeId: string, actionLogId: string }>()

  actionLogs: ActionLog[] = []
  firstActionLog: ActionLog | null = null
  lastActionLog: ActionLog | null = null

  actionLogChildren: Record<string, ActionLog[]> = {}

  @Mutation
  setActionLogsListStatus (...params: Parameters<typeof this.actionLogsListStatus.set>) {
    this.actionLogsListStatus.loadingInfo.promises?.forEach(o => o.cancel())
    this.actionLogsListStatus.set(...params)
  }

  @Mutation
  setActionLogChildrenListStatus (...params: Parameters<typeof this.actionLogChildrenListStatus.set>) {
    this.actionLogChildrenListStatus.loadingInfo.promise?.cancel()
    this.actionLogChildrenListStatus.set(...params)
  }

  @Mutation
  setActionLogs (actionLogs: ActionLog[]) {
    this.actionLogs = actionLogs
  }

  @Mutation
  setFirstActionLog (firstActionLog: ActionLog | null) {
    this.firstActionLog = firstActionLog
  }

  @Mutation
  setLastActionLog (lastActionLog: ActionLog | null) {
    this.lastActionLog = lastActionLog
  }

  @Mutation
  setActionLogChildren (actionLogChildren: Record<string, ActionLog[]>) {
    this.actionLogChildren = {
      ...this.actionLogChildren,
      ...actionLogChildren
    }
  }

  @Mutation
  resetActionLogChildren () {
    this.actionLogChildren = {}
  }

  @Action({ rawError: true })
  async actionLogsList ({ landscapeId, filter }: { landscapeId: string, filter?: ActionLogFilter }) {
    try {
      this.context.commit('setActionLogsListStatus', Status.loading({ filter, landscapeId }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const promises = [
        ActionLogsService.actionLogsList(authorization, landscapeId, filter),
        ActionLogsService.actionLogsList(authorization, landscapeId, {
          actionId: filter?.actionId,
          includeActionsInContext: filter?.includeActionsInContext,
          limit: 1
        }),
        ActionLogsService.actionLogsList(authorization, landscapeId, {
          actionId: filter?.actionId,
          actionType: filter?.actionType,
          includeActionsInContext: filter?.includeActionsInContext,
          limit: 20,
          order: 'desc'
        })
      ]

      this.context.commit('setActionLogsListStatus', Status.loading({ filter, landscapeId, promises }))

      const [
        { actionLogs },
        { actionLogs: [firstActionLog] },
        { actionLogs: [lastActionLog] }
      ] = await Promise.all(promises)

      this.context.commit('setActionLogs', actionLogs)
      this.context.commit('setFirstActionLog', firstActionLog || null)
      this.context.commit('setLastActionLog', lastActionLog || null)

      this.context.commit('setActionLogsListStatus', Status.success({ filter, landscapeId }))

      return {
        actionLogs,
        firstActionLog,
        lastActionLog
      }
    } catch (err: any) {
      const message = err.body?.message || err.message
      if (!(err instanceof CancelError)) {
        this.context.commit('setActionLogsListStatus', Status.error(message))
        this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      }
      throw err
    }
  }

  @Action({ rawError: true })
  async actionLogChildrenList ({ landscapeId, actionLogId }: { landscapeId: string, actionLogId: string }) {
    try {
      this.context.commit('setActionLogChildrenListStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const promise = ActionLogsService.actionLogChildrenList(authorization, landscapeId, actionLogId)

      this.context.commit('setActionLogChildrenListStatus', Status.loading({ actionLogId, landscapeId, promise }))

      const { actionLogs } = await promise
      this.context.commit('setActionLogChildren', {
        [actionLogId]: actionLogs
      })

      this.context.commit('setActionLogChildrenListStatus', Status.success({ actionLogId, landscapeId }))

      return actionLogs
    } catch (err: any) {
      const message = err.body?.message || err.message
      if (!(err instanceof CancelError)) {
        this.context.commit('setActionLogChildrenListStatus', Status.error(message))
        this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      }
      throw err
    }
  }
}
